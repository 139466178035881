<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span class="vol-event-name">发需求</span>
        <img src="../../assets/volunteer/hearts.png" class="vol-hearts" />

        <img src="../../assets/volunteer/require.png" class="vol-require" />
      </div>
      <div class="vol-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">发需求</li>
          </ol>
        </nav>
        <form class="row g-3" @submit.prevent="submit">
          <div class="col-md-12">
            <label class="form-label"> <span>*</span>服务类别（可多选）</label>
          </div>
          <div class="col-12">
            <div
              class="form-check form-check-inline"
              v-for="(item, index) in serviceList"
              :key="item.event_type_id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :id="'inlineCheckbox' + index"
                v-model="selType"
                :value="item.event_type_id"
              />
              <label class="form-check-label" :for="'inlineCheckbox' + index">{{
                item.event_type_name
              }}</label>
            </div>
          </div>

          <div class="col-md-12">
            <label class="form-label"><span>*</span>活动名称</label>
            <input
              type="text"
              required
              v-model="formdata.title"
              class="form-control"
              placeholder="请输入活动名称"
            />
          </div>

          <div class="col-md-6">
            <label class="form-label"><span>*</span>活动开始时间</label>
            <date-picker
              :editable="false"
              value-type="format"
              format="Y-MM-DD HH:ss"
              v-model="formdata.start_time"
              :lang="lang"
              type="datetime"
            ></date-picker>
          </div>
          <div class="col-md-6">
            <label class="form-label"><span>*</span>活动结束时间</label>
            <date-picker
              :editable="false"
              value-type="format"
              format="Y-MM-DD HH:ss"
              v-model="formdata.end_time"
              :lang="lang"
              type="datetime"
            ></date-picker>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>*</span>活动所属区域</label>
            <select
              id="input-province"
              v-model="formdata.province"
              class="form-select"
            >
              <option selected>{{ formdata.province }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>&nbsp;</span></label>
            <select
              id="input-city"
              placeholder="请选择市"
              v-model="formdata.city"
              @change="toChangeCity($event)"
              class="form-select"
            >
              <option
                selected
                :value="item.area_id"
                v-for="item in cityList"
                :key="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label class="form-label"><span>&nbsp;</span></label>
            <select
              id="input-district"
              placeholder="请选择区"
              v-model="formdata.district"
              @change="toChangeDistrict($event)"
              class="form-select"
            >
              <option
                selected
                :value="item.area_id"
                v-for="item in districtList"
                :key="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
          </div>
          <div class="col-md-12">
            <label class="form-label"><span>*</span>活动具体地点</label>
            <input
              type="text"
              required
              v-model="formdata.address"
              class="form-control"
              placeholder="请填写活动具体地点"
            />
          </div>
          <div class="col-md-12">
            <label class="form-label"><span>*</span>组织者</label>
            <input
              type="text"
              required
              v-model="formdata.management"
              class="form-control"
              placeholder="请输入组织者"
            />
          </div>
          <div class="col-md-12">
            <label class="form-label"
              ><span>*</span>活动简介(简介为必填, 图片非必传,
              图片最多上传9张)</label
            >
            <textarea
              class="form-control"
              placeholder="请输入组织简介"
              v-model="formdata.intro"
              rows="3"
              required
            ></textarea>
          </div>
          <div class="col-md-12">
            <div class="thumb-content">
              <div
                style="position: relative"
                v-for="(item, index) in thumbList"
                :key="item"
              >
                <img width="200" :src="item" />
                <i class="iconfont icon-clear" @click="deleteFile(index)"></i>
              </div>
            </div>
            <input
              class="form-control"
              type="file"
              accept="image/jpeg,image/png"
              id="formFileMultiple"
              @change="updateFile($event)"
              multiple
            />
          </div>
          <div class="col-12">
            <button type="submit" class="btn bottom-btn">提交</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { postEvent, areaList, uploadFile, eventType } from "@/network/API.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      formdata: {
        type_id: [],
        province: "河北省",
        city: "",
        district: "",
      },
      selType: [],
      cityList: [],
      districtList: [],
      selCity: {},
      selDistrict: {},
      serviceList: [],
      uploadFiles: [],
      thumbList: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    };
  },
  async beforeCreate() {},
  async created() {
    this.fetchService();
    this.getArea("130000", 0);
  },
  methods: {
    deleteFile(idx) {
      this.thumbList.splice(idx, 1);
      this.uploadFiles.splice(idx, 1);
    },
    async fetchService() {
      let res = await eventType();
      this.serviceList = res;
    },
    updateFile(event) {
      let files = event.target.files;
      if (files.length > 3) {
        this.$toast("最多支持九张图片");
        files = Array.from(files).slice(0, 3);
      }
      let that = this;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.uploadFiles.push(file);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          that.thumbList.push(this.result);
        };
      }
    },
    toChangeCity(e) {
      let code = e.target.value;
      this.selCity = this.cityList.filter((item) => {
        return item.area_id == code;
      })[0];
      this.getArea(this.selCity.area_id, 1);
    },
    toChangeDistrict(e) {
      this.selDistrict = this.selDistrict = this.districtList.filter((item) => {
        return item.area_id == e.target.value;
      })[0];
    },
    async getArea(code, type) {
      let list = await areaList({
        code: code,
        showLoading: true,
      });
      if (type == 0) {
        this.cityList = list;
      } else if (type == 1) {
        this.districtList = list;
      }
    },
    async submit(event) {
      if (this.selType.length >= 1) {
        this.formdata.type_id = this.selType.join(",");
      } else {
        this.$toast("请选择服务类别");
        return;
      }
      if (!this.formdata.title) {
        this.$toast("请输入活动名称");
      }
      if (!this.formdata.start_time) {
        this.$toast("请选择活动开始时间");
        return;
      }
      if (!this.formdata.end_time) {
        this.$toast("请选择活动结束时间");
        return;
      }
      if (!this.selCity.area_id) {
        this.$toast("请完善活动所属区域");
        return;
      }
      if (!this.selDistrict.area_id) {
        this.$toast("请完善活动所属区域");
        return;
      }
      if (this.uploadFiles.length == 0) {
        this.$toast("请上传图片");
        return;
      }
      this.formdata.province = "河北省";
      this.formdata.province_code = "130000";
      this.formdata.city = this.selCity.area_name;
      this.formdata.city_code = this.selCity.area_id;
      this.formdata.district = this.selDistrict.area_name;
      this.formdata.district_code = this.selDistrict.area_id;
      this.formdata.image = "";
      if (this.uploadFiles.length > 0) {
        var fileUrls = [];
        for (let i = 0; i < this.uploadFiles.length; i++) {
          let formdata = new FormData();
          formdata.append("file", this.uploadFiles[i]);
          let res = await uploadFile(formdata);
          fileUrls.push(res[0]);
        }
        this.formdata.image = fileUrls.join(",");
      }
      let res = await postEvent({
        ...this.formdata,
        showLoading: true,
      });
      this.$toast("发布成功");
      setTimeout(() => {
        this.$router.go(-1);
      }, 800);
      return;
    },
  },
};
</script>
<style>
.vol-hearts {
  top: -80px;
}

.vol-require {
  position: absolute;
  z-index: 3;
  right: 120px;
  top: 40px;
  width: 179px;
  height: 203px;
}

.bottom-btn {
  width: 260px;
  margin: 30px auto;
  position: relative;
  z-index: 4;
  background: linear-gradient(97deg, #ffb990 0%, #ff3241 100%);
  border: none;
  color: #fff !important;
}
.form-label span {
  color: red;
}
.thumb-content {
  display: flex;
  flex-direction: row;
}

.icon-clear {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
